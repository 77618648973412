/* eslint-disable */
import React, { useEffect, useState } from "react"

import { Block } from "baseui/block"
import { SEOPage } from "../components/SEO"
import Layout from "../components/layout"
import Container from "../components/UI/Container"

export default () => {
  const status = useScript("https://embed.typeform.com/embed.js")
  return (
    <Layout isSimple>
      <SEOPage title="Trial Signup" description="Blocklynx sign up"/>
      <Block paddingTop="50px" height="800px">
        <Container>
          {status === "ready" && (
            <Block
              marginTop="50px"
              position="relative"
              paddingTop="56.25%" /* 16:9 aspect ratio */
              height="0"
            >
              <iframe
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  minHeight: "550px",
                  border: 0,
                }}
                title="feedback"
                id="typeform-full"
                src="https://form.typeform.com/to/JbpnFSD4"
                allow="camera; microphone; autoplay; encrypted-media;"
              />
            </Block>
          )}
        </Container>
      </Block>
    </Layout>
  )
}

// https://usehooks.com/useScript/
function useScript(src) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? "loading" : "idle")

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus("idle")
        return
      }

      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`)

      if (!script) {
        // Create script
        script = document.createElement("script")
        script.src = src
        script.async = true
        script.setAttribute("data-status", "loading")
        // Add script to document body
        document.body.appendChild(script)

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = event => {
          script.setAttribute(
            "data-status",
            event.type === "load" ? "ready" : "error"
          )
        }

        script.addEventListener("load", setAttributeFromEvent)
        script.addEventListener("error", setAttributeFromEvent)
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute("data-status"))
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = event => {
        setStatus(event.type === "load" ? "ready" : "error")
      }

      // Add event listeners
      script.addEventListener("load", setStateFromEvent)
      script.addEventListener("error", setStateFromEvent)

      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener("load", setStateFromEvent)
          script.removeEventListener("error", setStateFromEvent)
        }
      }
    },
    [src] // Only re-run effect if script src changes
  )

  return status
}
